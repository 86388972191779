<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :class="{ row, error: v$.user.givenName.$errors.length }"
            :label="$t('forms.givenName')"
            label-for="h-first-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="user.givenName" id="h-first-name"/>
            <div
              class="input-errors"
              v-for="error of v$.user.givenName.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.user.familyName.$errors.length }"
            :label="$t('forms.familyName')"
            label-for="h-last-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="user.familyName" id="h-last-name"/>
            <div
              class="input-errors"
              v-for="error of v$.user.familyName.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            class="row"
            :label="$t('forms.airline')"
            label-for="h-airline"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input
              id="h-airline"
              name="Airline"
              v-model="user.airline"
            />
          </b-form-group>
          <b-form-group
            class="row"
            :label="$t('forms.nationality')"
            label-for="h-nationality"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input
              id="h-nationality"
              name="Nationality"
              v-model="user.nationality"
            />
          </b-form-group>
          <b-form-group
            class="row"
            :label="$t('forms.telephone')"
            label-for="h-phone"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input id="h-phone" v-model="user.telephone"/>

            
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.user.email.$errors.length }"
            :label="$t('forms.email')"
            label-for="h-email"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input id="h-email" type="email" v-model="user.email"/>
            <div
              class="input-errors"
              v-for="error of v$.user.email.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            class="row"
            :label="$t('forms.comment')"
            label-for="h-comment"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input
              id="h-comment"
              name="Comment"
              v-model="user.description"
            />
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.user.plainPassword.$errors.length }"
            :label="$t('forms.password')"
            label-for="password"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input
              id="password"
              type="password"
              v-model="user.plainPassword"
            />
            <BootstrapIcon
              icon="eye"
              size="1x"
              class="showIcon"
              @click="togglePassword"
            />
            <div
              class="input-errors"
              v-for="error of v$.user.plainPassword.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            class="row"
            :label="$t('forms.aviationId')"
            label-for="h-comment"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <image-upload v-if="user['@id']" :resource="user['@id']" file-attribute="FILE" :preview-image="fileURL"
                  @fileUploaded="setPhoto"/>
          </b-form-group>
          
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {BRow, BCol, BFormGroup, BFormInput, BForm} from "bootstrap-vue-3";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ImageUpload from "@/components/Inputs/ImageUpload.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ImageUpload
  },
  props: {
    userObject: {Object, required: true},
    action: {type: String, default: null},
  },
  mixins: [ResourceUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      user: {
        givenName: {required},
        familyName: {required},
        email: {required, email},
        plainPassword: {
          minLengthValue: minLength(8),
        },
      },
    };
  },
  created() {
    if (this.userObject.fileUrl) {
      this.fileURL = this.userObject.fileUrl;
    }
  },  
  data() {
    return {
      user: this.userObject,
      fileURL: null
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    togglePassword() {
      const password = document.querySelector("#password");
      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    },
    setPhoto(data) {
      this.user.file = data["@id"];
      this.fileURL = data.url;
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        if (this.user.plainPassword === "") {
          delete this.user.plainPassword;
        }
        this.user.address.flatNumber = this.user.address.flatNumber
          ? parseInt(this.user.address.flatNumber, 10)
          : null;
        this.user.address.floorNumber = this.user.address.floorNumber
          ? parseInt(this.user.address.floorNumber, 10)
          : null;

        if (this.action) {
          this.action === "add" ?
            this.create(this.$Users, this.user, this.$t("messages.customerCreated"), null, this.success, this.error) :
            this.update(this.$Users, this.user.id, this.user, this.$t("messages.customerUpdated"), null, null, this.error);
          this.$emit("clearAction");
        }

      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/`);
    },
    error() {
      this.$emit("clearAction");
    }
  },
};
</script>
